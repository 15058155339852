import React from 'react'
import Layout from '../../components/Layout'
import {graphql, StaticQuery} from "gatsby";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import voited from '../../img/voited.png';
function encode(data) {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
}

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isValidated: false,
      date: new Date(),
      isOpen: false,
      invalid: false,
      submitted: false,
      loading: false,
      error: false,
      adds: {}
    }
  }

  handleChange = ({target: {name, checked, value, type}}) => {
    // console.log(name, checked, type, value)
    const {adds} = this.state;
    const isCheck = type === 'checkbox';
    if (isCheck && checked) {
      adds[value] = "ja"
      return this.setState({adds})
    }
    if (isCheck && !checked) {
      const {[value]: old, ...other} = adds
      return this.setState({adds: other})
    } else {
      this.setState({[name]: isCheck ? checked : value})
    }
  }

  onDateRangeChange = date => {
    this.setState({date, invalid: !Array.isArray(date)})
  }

  toggleCalendar = () => this.setState({isOpen: !this.state.isOpen})

  handleSubmit = e => {
    e.preventDefault()
    this.setState({loading: true})
    const {name, email, telefon, fahrzeug, reiseziel, date, nachicht, adds} = this.state;
    if (!Array.isArray(date)) return this.setState({invalid: true})
    const dateString = `${date[0].toLocaleDateString()} - ${date[1].toLocaleDateString()}`
    const extras = Object.keys(adds).reduce((acc, val) => `${acc}, ${val} `, '');
    const data = {name, email, fahrzeug, reiseziel, telefon, date: dateString, nachicht: nachicht || '-', extras}
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      body: encode({
        'form-name': form.getAttribute('name'),
        ...data,
      }),
    })
        .then(() => {
          this.setState({submitted: true, loading: false, error: false}, () => window.scrollTo({
            top: 0,
            behavior: 'smooth'
          }))
        })
        .catch(error => {
          console.log(error);
          this.setState({submitted: false, loading: false, error: true}, () => window.scrollTo({
            top: 0,
            behavior: 'smooth'
          }))
        })
  }

  render() {
    // console.log(this.state)
    const {location, data} = this.props;
    const {isOpen, date, invalid, submitted, error, loading} = this.state;
    const {vehicles: {edges: vehicleList}} = data
    const showCalendar = !isOpen ? 'is-hidden' : ''
    return (
        <Layout location={location}>
          <section className="section">
            <div className="container">
              {error &&
              <div className="content column is-10 is-offset-1 is-centered is-vcentered">
                <article className="message danger">
                  <div className="message-header">
                    <p>Fehler</p>
                  </div>
                  <div className="message-body">
                    Wir konnten die Nachicht nicht übermitteln, bitte erneut versuchen.
                  </div>
                </article>
              </div>
              }
              {submitted &&
              <div className="content column is-10 is-offset-1 is-centered is-vcentered">
                <article className="message is-success">
                  <div className="message-header">
                    <p>Thank you for your request</p>
                  </div>
                  <div className="message-body">
                    wir haben Ihre Nachicht erhalten und kommen umgehend auf Sie zurück.
                  </div>
                </article>
              </div>
              }
              {!submitted &&
              <div className="content column is-10 is-offset-1">
                <React.Fragment>
                  <h1>Kontakt</h1>
                  <form
                      name="booking"
                      method="post"
                      // action="/contact/booking/"
                      data-netlify="true"
                      data-netlify-honeypot="bot-field"
                      onSubmit={this.handleSubmit}
                  >
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input type="hidden" name="form-name" value="booking" disabled={true}/>
                    <div hidden>
                      <label>
                        Don’t fill this out:{' '}
                        <input name="bot-field" onChange={this.handleChange}/>
                      </label>
                    </div>
                    <fieldset disabled={false}>
                      <div className="field">
                        <label className="label" htmlFor={'name'}>
                          Name
                        </label>
                        <div className="control">
                          <input
                              className="input"
                              type={'text'}
                              name={'name'}
                              onChange={this.handleChange}
                              id={'name'}
                              required={true}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label className="label" htmlFor={'email'}>
                          Email
                        </label>
                        <div className="control">
                          <input
                              className="input"
                              type={'email'}
                              name={'email'}
                              onChange={this.handleChange}
                              id={'email'}
                              required={true}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label className="label" htmlFor={'message'}>
                          Fahrzeug
                        </label>
                        <div className="control">
                          <div className="select is-fullwidth">
                            <select name="fahrzeug" defaultValue={'-'} value={this.state.fahrzeug}
                                    onChange={this.handleChange}>
                              {vehicleList.map(({node: {id, frontmatter}}) =>
                                  <option value={frontmatter.title} key={id}>{frontmatter.title}</option>
                              )}
                              <option value={"-"} key={"-"}>{"-"}</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="field">
                        <label className="label" htmlFor={'email'}>
                          Buchungszeitraum
                        </label>
                        <div className="control">
                          <input
                              className={`${invalid ? 'input is-disabled is-danger' : 'input is-disabled'}`}
                              type={'string'}
                              name={'date'}
                              value={date && Array.isArray(date) ? `${date[0].toLocaleDateString()} - ${date[1].toLocaleDateString()}` : date.toLocaleDateString()}
                              onClick={this.toggleCalendar}
                              readOnly
                              required={true}
                          />
                          <p className={`${invalid ? "help is-danger" : "is-hidden"}`}>Unvollständiges Reisedaten</p>
                          <div className={isOpen ? "modal is-active" : "modal"}>
                            <div className="modal-background" onClick={this.toggleCalendar}/>
                            <div className="modal-content columns">
                              <section className="section" style={{padding: 16, backgroundColor: 'white'}}>
                                <Calendar
                                    className={showCalendar}
                                    selectRange={true}
                                    onChange={this.onDateRangeChange}
                                    value={this.state.date}
                                />
                                <br/>
                                <br/>
                                <div className="button is-rounded"
                                     style={{borderColor: 'transparent', marginRight: 16, border: '1px solid grey'}}
                                     onClick={this.toggleCalendar}>
                                  abbrechen
                                </div>
                                <div className="button is-rounded bluebg" style={{borderColor: 'transparent'}}
                                     onClick={this.toggleCalendar}>
                                  übernehmen
                                </div>
                              </section>
                            </div>

                            <button className="modal-close is-large" aria-label="close"
                                    onClick={this.toggleCalendar}/>
                          </div>

                        </div>
                      </div>

                      <div className="field">
                        <label className="label" htmlFor={'message'}>
                          Reiseziel
                        </label>
                        <div className="control">
                          <input
                              className="input"
                              type={'string'}
                              name={'reiseziel'}
                              onChange={this.handleChange}
                              id={'reiseziel'}
                              // required={true}
                          />
                        </div>
                      </div>

                      <div className="field">
                        <label className="label" htmlFor={'message'}>
                          Telefonnummer
                        </label>
                        <div className="control">
                          <input
                              className="input"
                              type={'string'}
                              name={'telefon'}
                              onChange={this.handleChange}
                              id={'telefon'}
                              // required={true}
                          />
                        </div>
                      </div>

                      <div className="field">
                        <label className="label" htmlFor={'message'}>
                          Nachicht
                        </label>
                        <div className="control">
                            <textarea className="textarea" id="nachicht" name="nachicht" cols="35" rows="4"
                                      onChange={this.handleChange}/>
                        </div>
                      </div>
                      <br/>
                      <div className="field">
                        <label className="label" htmlFor={'message'}>
                          <a className="button is-link" href={'https://www.wuerzburger.com/tarifrechner/default.aspx?productid=15&partnerid=1-8-5267[https://www.wuerzburger.com/tarifrechner/default.aspx?productid=15&partnerid=1-8-5267]'}
                             target="_blank">
                              Zur Camper sorglos Reiseversicherrung geht es hier
                          </a>
                        </label>
                      </div>
                      <br/>

                      <fieldset>
                        <div className="field">
                          <label className="checkbox">
                            <input id="Fahrradträger" type="checkbox" name={'extras'}
                                   value={"Fahrradträger gewünscht (einmalig 50€)"} onChange={this.handleChange}/>
                            &nbsp; Fahrradträger gewünscht (einmalig 50€)
                          </label>
                        </div>
                        <div className="field">
                          <label className="checkbox">
                            <input id="Steinschlagversicherung" type="checkbox" name="extras"
                                   value={"Steinschlagversicherung gewünscht (15€ pro Miettag)"}
                                   onChange={this.handleChange}/>
                            &nbsp; Steinschlagversicherung gewünscht (15€ pro Miettag)
                          </label>
                        </div>
                        <div className="field">
                          <label className="checkbox">
                            <input id="Fahrer" type="checkbox" name="extras"
                                   value={"Zusätzlicher Fahrer (einmalig 50€)"} onChange={this.handleChange}/>
                            &nbsp; Zusätzlicher Fahrer (einmalig 50€)
                          </label>
                        </div>
                        <div className="field">
                          <label className="checkbox">
                            <input id="Navigationssystem" type="checkbox" name="extras"
                                   value={"Navigationssystem (10€ pro Woche, 100€ Kaution)"}
                                   onChange={this.handleChange}/>
                            &nbsp; Navigationssystem (10€ pro Woche, 100€ Kaution)
                          </label>
                        </div>
                        <div className="field">
                          <label className="checkbox">
                            <input id="Stellplatz" type="checkbox" name="extras"
                                   value={"Stellplatz für privaten PKW/Tiefgarage (je Woche 25€)"}
                                   onChange={this.handleChange}/>
                            &nbsp; Stellplatz für privaten PKW/Tiefgarage (je Woche 25€)
                          </label>
                        </div>
                        <div className="field">
                          <label className="checkbox">
                            <input id="Komfortschlafauflage" type="checkbox"
                                   name="extras"
                                   value={'Komfortschlafauflage für VW California Modelle  (je Woche 35€)'}
                                   onChange={this.handleChange}/>
                            &nbsp; Komfortschlafauflage für VW California Modelle (je Woche 35€)
                          </label>
                        </div>
                        <div className="field">
                          <label className="checkbox">
                            <input id="Campingtoilette" type="checkbox" name="extras"
                                   value={"Campingtoilette für VW Bus (einmalig 50€)"} onChange={this.handleChange}/>
                            &nbsp; Campingtoilette für VW Bus (einmalig 50€)
                          </label>
                        </div>
                        <div className="field">
                          <label className="checkbox">
                            <input id="Campinggeschirr" type="checkbox" name="extras"
                                   value={"Campinggeschirr 4 Personen einmalig 50,-€"} onChange={this.handleChange}/>
                            &nbsp; Campinggeschirr 4 Personen einmalig 50,-€
                          </label>
                        </div>
                        <div className="field">
                          <label className="checkbox">
                            <input id="Töpfe, Pfannen, Besteck" type="checkbox" name="extras"
                                   value={"Töpfe, Pfannen, Besteck einmalig 50,-€"} onChange={this.handleChange}/>
                            &nbsp; Töpfe, Pfannen, Besteck einmalig 50,-€
                          </label>
                        </div>
                        <div className="field">
                          <label className="checkbox">
                            <input id="CostaBoard" type="checkbox" name="extras"
                                   value={"CostaBoard je Miettag 5,-€ (Kaution 150,-€)"} onChange={this.handleChange}/>
                            &nbsp; CostaBoard je Miettag 5,-€ (Kaution 150,-€)
                          </label>
                        </div>
                        <div className="field">
                          <label className="checkbox">
                            <input id="Campingstuhl" type="checkbox"
                                   value={'Campingstuhl je Miettag 2,-€'}
                                   name="extras" onChange={this.handleChange}/>
                            &nbsp; Campingstuhl je Miettag 2,-€
                          </label>
                        </div>
                        <div className="field">
                          <label className="checkbox">
                            <input id="Voited Decke" type="checkbox"
                                   value={'Voited Decke (Kaution 140€)'}
                                   name="extras" onChange={this.handleChange}/>
                            &nbsp; Voited Decke (3,-€ je Miettag)
                          </label>
                        </div>
                        <img src={voited} alt={'voited blanket'} width={400} height={200}/>
                      </fieldset>
                      <br/>
                      <div className="field">
                        <button className="button is-link" type="submit">
                          Abschicken
                        </button>
                      </div>
                    </fieldset>
                  </form>
                </React.Fragment>
              </div>
              }
            </div>
          </section>
        </Layout>
    )
  }
}

const query = graphql`
    query ContactQuery {
        vehicles: allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "vehicle-page" } } }
        ) {
            edges {
                node {
                    excerpt(pruneLength: 400)
                    id
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        category
                        teaser_en
                        detail_header
                        detail_header_en
                        details {
                            detail
                            detail_name_de
                            detail_value_de
                        }
                        komfort_header
                        komfort_header_en
                        komfort {
                            komfort_en
                        }
                        preise {
                            start
                            end
                            price
                            name
                        }
                        templateKey
                        date(formatString: "MMMM DD, YYYY")
                        images {
                            title
                            alt
                            image {
                                name
                                childImageSharp {
                                    fluid(maxWidth: 526, quality: 92) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }`

export default (props) => (
    <StaticQuery
        query={query}
        render={(data, count) => <Index data={data} {...props} />}
    />
)
